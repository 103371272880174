<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="0" md="2" lg="4"> </v-col>
      <v-col cols="12" md="8" lg="4">

        <v-card style="border-radius: 8px; box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;">
          <v-card-text class="black--text">
            <v-form ref="loginForm" class="px-5" :disabled="loading">
              <div class="text-center my-10">
                <h3 class="my-4">{{ $t("AppBar.Login.welcome") }}</h3>

                <h5 class="text-sm grey--text text--darken-2 font-600 mb-8">
                  {{ $t("AppBar.Login.decription") }}
                </h5>
              </div>

              <div>
                <p class="text-14 mb-2">
                  {{ $t("AppBar.Login.username") }}
                </p>

                <v-text-field v-model="user.username" placeholder="example@mail.com" outlined dense hide-details=""
                  class="mb-4" :rules="[$sv.required]" v-on:keyup.enter="() => login()" :disabled="loading" />
              </div>

              <div>
                <p class="text-14 mb-2">
                  {{ $t("AppBar.Login.password") }}
                </p>

                <v-text-field v-model="user.password" type="password" placeholder="*******" outlined dense
                  hide-details="" class="mb-8" :rules="[$sv.required]" v-on:keyup.enter="() => login()"
                  :disabled="loading" />
              </div>

              <v-btn color="primary" class="text-capitalize font-600 py-5" block @click="() => login()"
                :disabled="loading" :loading="loading">
                {{ $t("AppBar.Login.login") }}
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-text class="text-center">
            <span class="grey--text text--darken-1">
              {{ $t("AppBar.Login.account") }}

              <router-link class="font-600" :to="`/entities/${$route.params.entidadId}/signup`" v-text="$t('AppBar.Login.signup')"></router-link>
            </span>
          </v-card-text>

          <v-card-text class="py-4 text-center grey lighten-2">
            <div class="grey--text text--darken-1">
              {{ $t("AppBar.Login.forgot") }}

              <router-link class="font-600" :to="`/entities/${$route.params.entidadId}/recovery`" v-text="$t('AppBar.Login.reset')"></router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="0" md="2" lg="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      user: {
        password: "",
        username: "",
      },
      loading: false,
    };
  },
  methods: {
    login() {
      const success = this.$refs.loginForm.validate();

      if (success) {
        this.loading = true;

        this.$store
          .dispatch("authentication/login", this.user)
          .then(() => {
            // SE DESACTIVA LA ANIMACION DE CARGA
            this.loading = false;

            // SE ELIMINAN LOS DATOS DE USUARIO DEL FORMULARIO
            this.user = {};

            this.$store.dispatch("cart/getCart");

            // SE ENVIA A LA PANTALLA DE PERFIL DE USUARIO
            this.$router.push(`/entities/${this.$route.params.entidadId}/account`).catch(() => { })
          })
          .catch((err) => {
            this.loading = false;

            // SE MUESTRA EL MENSAJE DE ERROR
            this.$dialog({
              title: "Error",
              text: err,
            });
          });
      }
    },
  },
};
</script>